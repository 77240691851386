/* unplugin-vue-components disabled */import serviceAxios from '@/utils/serviceAxios';
import { useRouter } from 'vue-router';
export default {
  __name: 'pointShow',
  setup(__props) {
    const router = useRouter();
    const pointShow = () => {
      serviceAxios({
        url: '/v1/info_dis/point_show/get_token',
        method: 'post'
      }).then(result => {
        if (result.data.message = 'success') {
          let pointShowUrl = result.data.data;
          window.open(pointShowUrl);
        } else {
          message.error(result.data.message);
        }
      }).catch(error => {
        console.log(error);
      });
    };
    pointShow();
    return (_ctx, _cache) => {
      return null;
    };
  }
};
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import serviceAxios from '@/utils/serviceAxios';
import interfaces from '@/config';
import { ref, reactive, watch } from 'vue';
import { message } from "ant-design-vue";
const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB

export default {
  __name: 'uploadComponent',
  props: {
    fileType: {
      type: String,
      default: "data_attachment"
    },
    // fileList: {
    //   type: Array,
    //   // eslint-disable-next-line vue/require-valid-default-prop
    //   default: [{
    //     uid: '-1',
    //     name: 'image.png',
    //     status: 'done',
    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //   }]
    // },
    fileIds: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    buttonName: {
      type: String,
      default: "上传"
    },
    ///v1/oss/file_type/selector
    fileType: {
      type: String,
      default: "一般文件"
    },
    //刷新组件
    freshFlag: {
      type: Boolean,
      default: false
    },
    //上传按钮的显示
    isButtonShow: {
      type: Boolean,
      default: true
    },
    maxCount: {
      type: Number
    },
    inputShow: {
      type: Boolean,
      default: true
    },
    descStyle: {
      type: Object,
      default: null
    }
  },
  emits: ['update:fileIds'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    let isImage = true;
    const handleChange = info => {
      console.log('fileList', fileList.value);
      console.log(info);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        emit('update:fileIds', info.fileList);
      }
      if (info.file.status === 'done') {
        console.log('done_info', info);
        // eslint-disable-next-line vue/no-mutating-props

        emit('update:fileIds', info.fileList);
        message.success(`${info.file.name} 上传成功`);
        let fileAlias = aliasRef.value == undefined ? "" : `(${aliasRef.value})`;
        fileList.value[fileList.value.length - 1].name = fileList.value[fileList.value.length - 1].name + fileAlias;
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed  .`);
      }
    };
    const uploadUrl = process.env.VUE_APP_URL + interfaces.ossUpload;
    const lookPic = process.env.VUE_APP_URL + interfaces.ossGet;
    const headers = {
      authorization: "Bearer " + localStorage.getItem("token")
    };

    // 定义文件别名
    const aliasRef = ref();
    //文件的类型 可以从/v1/file/get_file_type_selector接口获取
    const fileData = {
      file_type: props.fileType
    };

    //文件
    const fileList = ref([
      //   {
      //   uid: '-1',
      //   name: 'image.png',
      //   status: 'done',
      //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // }
    ]);

    // 动态获取图片 URL 的函数
    const getProxyImageUrl = async (originalUrl, file) => {
      try {
        const response = await serviceAxios({
          url: originalUrl,
          method: 'get',
          headers: headers,
          params: {
            file_id: file.file_id,
            owner_class: file.owner_class,
            owner_id: file.owner_id
          },
          responseType: 'blob' // 设置响应类型为 blob
        });
        const contentType = response.headers['content-type'] || 'application/octet-stream';
        // 创建 Blob URL
        const blob = new Blob([response.data], {
          type: contentType
        }); //
        const url = URL.createObjectURL(blob);
        console.log('url', url);
        return url;
      } catch (error) {
        console.error('Error fetching image:', error);
        throw error;
      }
    };
    const getImageInfo = async file => {
      const response = await serviceAxios({
        url: interfaces.ossGetFileInfo,
        method: 'get',
        params: {
          file_id: file.file_id
        }
      });
      const data = response.data.data;
      let fileAlias = data.alias == '' || data.alias == 'undefined' ? "" : `(${data.alias})`;
      return {
        name: data.name + fileAlias,
        id: data.id
      };
    };
    // 更新 fileList 中的 url 和 thumbUrl
    const updateFileListUrls = async () => {
      fileList.value = [];
      for (const file of props.fileIds) {
        // const url = lookPic;
        const responseUrl = await getProxyImageUrl(lookPic, file);
        const responsrImageInfo = await getImageInfo(file);
        fileList.value.push({
          uid: responsrImageInfo.id,
          name: responsrImageInfo.name,
          status: 'done',
          url: responseUrl,
          isOld: true //用于判断是否是新上传的文件
        });
      }
    };
    updateFileListUrls();

    //刷新组件
    watch(() => props.freshFlag, (newVal, oldVal) => {
      console.log('新旧数据', newVal, oldVal);
      updateFileListUrls();
    });
    const beforeUpload = file => {
      // return new Promise((resolve) => {
      //   console.log('beforeUpload triggered', file);
      //   isImage = file.type.startsWith('image/');
      //   if (!isImage) {
      //     message.error(`${file.name} 不是一个图片文件`);
      //     return false
      //   }
      //   console.log('beforeUpload isImage', isImage)
      //   // aliasRef.value = ""
      //   return resolve(true);
      // });

      return new Promise((resolve, reject) => {
        console.log('beforeUpload triggered', file);
        // isImage = file.type.startsWith('image/');

        // if (!isImage) {
        //   message.error(`${file.name} 不是一个图片文件`);
        //   return reject(false);
        // }

        if (file.size > MAX_FILE_SIZE) {
          message.error(`${file.name} 大小超过限制（最大100MB）`);
          return false;
        }
        if (file.size == 0) {
          message.error(`${file.name} 是空文件`);
          return false;
        }
        console.log('beforeUpload isImage', isImage);
        resolve(true);
      });
    };
    // 自定义的下载和预览方法
    const previewFile = item => {
      // 根据文件的后缀进行预览或是下载操作
      if (item.name.endsWith('.jpg') || item.name.endsWith('.png')) {
        const imgWindow = window.open('');
        imgWindow && imgWindow.document.write(`<img src='${item.url}' style='display: block; margin: 0 auto;'>`);
      }
      // else if (item.name.endsWith('.pdf')) {
      //   // 使用 iframe 嵌入 PDF 文件
      //   const pdfWindow = window.open('')
      //   pdfWindow && pdfWindow.document.write(`
      //     <html>
      //       <head>
      //         <style>
      //           body { margin: 0; padding: 0; }
      //           iframe { width: 100%; height: 100vh; }
      //         </style>
      //       </head>
      //       <body>
      //         <iframe src="${item.url}" frameborder="0"></iframe>
      //       </body>
      //     </html>
      //   `);
      // }
      else {
        if (item.url) {
          downloadFile(item.url, item.name);
        }
      }
    };

    // 下载文件 将后台返回的文件url链接转换为文件流格式，点击打开新窗口下载文件
    const downloadFile = async (url, fileName = null) => {
      const a = document.createElement('a'); // 创建a标签
      if (fileName) {
        const response = await fetch(url); // 内容转变成blob地址
        const blob = await response.blob(); // 创建隐藏的可下载链接
        const objectUrl = window.URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = fileName;
      } else {
        a.href = url;
      }
      a.click();
      a.remove();
    };
    return (_ctx, _cache) => {
      const _component_a_input = _resolveComponent("a-input");
      const _component_upload_outlined = _resolveComponent("upload-outlined");
      const _component_a_button = _resolveComponent("a-button");
      const _component_a_upload = _resolveComponent("a-upload");
      return _openBlock(), _createElementBlock(_Fragment, null, [props.inputShow ? (_openBlock(), _createBlock(_component_a_input, {
        key: 0,
        addonBefore: "文件备注",
        value: aliasRef.value,
        "onUpdate:value": _cache[0] || (_cache[0] = $event => aliasRef.value = $event),
        placeholder: "文件备注",
        style: {
          "width": "200px",
          "margin": "5px 5px 5px 0px",
          "color": "#eceef0"
        }
      }, null, 8, ["value"])) : _createCommentVNode("", true), _createVNode(_component_a_upload, {
        "file-list": fileList.value,
        "onUpdate:fileList": _cache[1] || (_cache[1] = $event => fileList.value = $event),
        action: uploadUrl,
        "before-upload": beforeUpload,
        "list-type": "picture",
        headers: headers,
        data: {
          type: props.fileType,
          alias: aliasRef.value
        },
        onChange: handleChange,
        fileIds: props.fileIds,
        onPreview: previewFile,
        fresh: props.freshFlag,
        "max-count": props.maxCount
      }, {
        default: _withCtx(() => [props.isButtonShow ? (_openBlock(), _createBlock(_component_a_button, {
          key: 0,
          style: {
            "margin": "5px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_upload_outlined), _createTextVNode(" " + _toDisplayString(props.buttonName), 1)]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["file-list", "data", "fileIds", "fresh", "max-count"])], 64);
    };
  }
};
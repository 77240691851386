import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
// import Antd from "ant-design-vue";
// import * as Icons from "@ant-design/icons-vue";
// import { message } from "ant-design-vue";

// 导入全局样式表
import "./assets/css/global.css";
import "ant-design-vue/dist/reset.css";
// 正式环境清除所有console.log
if (process.env.NODE_ENV === "production") {
  if (window) {
    window.console.log = function () {};
  }
}

const app = createApp(App);

// 注册所有图标组件
// const icons = Icons;
// for (const i in icons) {
//   app.component(i, icons[i]);
// }

// 使用 Antd 和其他插件
// app.use(Antd);
app.use(router);
app.use(store);
// app.config.globalProperties.$message = message;

app.mount("#app");

import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { reactive, onMounted, useSlots } from "vue";
import { useScreenSize } from '@/utils/useScreenSize';
export default {
  __name: 'TableComponent',
  props: {
    tableScrollY: {
      type: Number,
      default: 320
    },
    current: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  setup(__props) {
    const slots = useSlots();
    const props = __props;
    const {
      windowWidth,
      windowHeight
    } = useScreenSize();
    const windowSize = reactive({
      x: "",
      y: ""
    });
    const tableHeight = () => {
      let height = 0;
      let containerName = ['.layout-header', 'ol', '.ant-table-title', '.ant-pagination', '.footer'];
      containerName.forEach(item => {
        let con = document.querySelector(item);
        // console.log(item, con)

        if (con != null) {
          height += con.offsetHeight;
        }
      });
      windowSize.y = windowHeight.value - height - 80;
      // console.log("windowHeight.value", windowHeight.value, height)
    };
    onMounted(() => {
      tableHeight();
    });
    window.addEventListener('resize', () => {
      tableHeight();
    });
    return (_ctx, _cache) => {
      const _component_a_table = _resolveComponent("a-table");
      return _openBlock(), _createBlock(_component_a_table, _mergeProps({
        pagination: false,
        scroll: windowSize,
        style: {
          "overflow": "hidden !important",
          "white-space": "nowrap",
          "text-overflow": "ellipsis"
        }
      }, _ctx.$attrs), _createSlots({
        _: 2
      }, [_renderList(_unref(slots), (item, key, i) => {
        return {
          name: key,
          fn: _withCtx(({
            record,
            rowIndex,
            column
          }) => [_renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps({
            rowIndex: rowIndex,
            record: record,
            column: column
          })))])
        };
      })]), 1040, ["scroll"]);
    };
  }
};
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

import pic from "../views/synthesis/customer/pic.vue";
import pic2 from "../views/synthesis/customer/pic2.vue";

//无数据模板
import resultComponent from "../components/resultComponent.vue";

//测试
import homePage from "../views/demo/HomeViewDemo.vue";
import login from "../views/demo/LoginViewDemo.vue";
import homePageData from "../views/demo/HomeDataDemo.vue";
import glopt from "../views/demo/glopt.vue";
import storeDetailPage from "../views/demo/storeDetailPage.vue";
import storeMessage from "../views/demo/storeMessage.vue";
import customerView from "../views/demo/customerView.vue";
import ticketView from "../views/demo/ticketView.vue";
import addTicket from "../views/demo/addTicket.vue";
import pointShow from "../views/demo/pointShow.vue";
import accountManage from "../views/demo/accountManage.vue";
import customerTemperatureSensorList from "../views/demo/sensorList.vue";
import customeriotStoreConfig from "../views/demo/iotStoreConfig.vue";
import sensorLogList from "../views/demo/sensorLogList.vue";
import sensorDetails from "../views/demo/sensorDetails.vue";
import customerWorkOrder from "../views/demo/workOrder.vue";
import energyDetailPage from "../views/demo/energyDetailPage";
import customerEnergySensorList from "../views/demo/energySensorList";

import AssetsType from "../views/assets/AssetsType.vue";
import AssetsModel from "../views/assets/AssetsModel.vue";
import AssetsSubSystem from "../views/assets/AssetsSubSystem.vue";
import AssetsList from "../views/assets/AssetsList.vue";
import AssetsUsageRecords from "../views/assets/AssetUsageRecords.vue";
import AssetsGlobalConfig from "../views/assets/AssetsGlobalConfig.vue";
import AssetsTransferRecord from "../views/assets/AssetsTransferRecord.vue";
import AssetsRepairRecord from "../views/assets/AssetsRepairRecord.vue";
import AssetsScrapRecord from "../views/assets/AssetsScrapRecord.vue";
import AssetsScrapMethod from "../views/assets/AssetsScrapMethod.vue";

import GatewayAlerts from "../views/alertsLog/GatewayAlerts.vue";
import nodeAlert from "../views/alertsLog/nodeAlert.vue";
import MessageSendStrategy from "../views/messageCenter/MessageSendStrategy.vue";
const routes = [
  {
    path: "/pointShow",
    component: pointShow,
    meta: { breadcrumb: "信发系统" },
  },

  {
    // 测试
    path: "/login",
    component: login,
    children: [],
  },

  {
    path: "/homePage",
    component: homePage,
    redirect: "/homePageData",
    meta: { breadcrumb: "首页" },
    children: [
      {
        path: "/homePageData",
        component: homePageData,
        meta: { breadcrumb: "首页数据概括" },
      },
      {
        path: "/customerWorkOrder",
        component: customerWorkOrder,
        // redirect: '/welcome',
        children: [],
      },
      {
        path: "/energyDetailPage",
        component: energyDetailPage,
        // redirect: '/welcome',
        children: [],
      },
      {
        path: "/customerEnergySensorList",
        component: customerEnergySensorList,
        // redirect: '/welcome',
        children: [],
      },
      {
        path: "/customerTemperatureSensorList",
        component: customerTemperatureSensorList,
        // redirect: '/welcome',
        children: [],
      },
      {
        path: "/customeriotStoreConfig",
        component: customeriotStoreConfig,
        // redirect: '/welcome',
        children: [],
      },
      {
        path: "/messageSendStrategy",
        component: MessageSendStrategy,
        // redirect: '/welcome',
        children: [],
      },
      {
        path: "/sensorLogList",
        component: sensorLogList,
        // redirect: '/welcome',
        children: [],
      },
      {
        path: "/sensorDetails",
        component: sensorDetails,
        // redirect: '/welcome',
        children: [],
      },
      {
        path: "/glopt",
        component: glopt,
        meta: { breadcrumb: "拓扑图" },
      },
      {
        path: "/customerView",
        component: customerView,
        meta: { breadcrumb: "门店信息" },
      },
      {
        path: "/storeDetailPage",
        component: storeDetailPage,
        meta: { breadcrumb: "门店网络详情" },
      },
      {
        path: "/storeMessage",
        component: storeMessage,
        meta: { breadcrumb: "门店数据" },
      },
      {
        path: "/ticketView",
        component: ticketView,
        meta: { breadcrumb: "工单列表" },
      },
      {
        path: "/accountManage",
        component: accountManage,
        meta: { breadcrumb: "账户管理" },
      },
      {
        path: "/addTicket",
        component: addTicket,
        meta: { breadcrumb: "添加工单" },
      },
      //资产
      {
        path: "/assetsType",
        component: AssetsType,
        meta: { breadcrumb: "资产类型" },
      },
      {
        path: "/assetsList",
        component: AssetsList,
        meta: { breadcrumb: "资产列表" },
      },
      {
        path: "/assetsModel",
        component: AssetsModel,
        meta: { breadcrumb: "资产型号" },
      },
      {
        path: "/assetsSubSystem",
        component: AssetsSubSystem,
        meta: { breadcrumb: "资产子系统" },
      },
      {
        path: "/assetsUsageRecords",
        component: AssetsUsageRecords,
        meta: { breadcrumb: "资产使用记录" },
      },
      {
        path: "/assetsGlobalConfig",
        component: AssetsGlobalConfig,
        meta: { breadcrumb: "资产全局配置" },
      },
      {
        path: "/assetsTransferRecord",
        component: AssetsTransferRecord,
        meta: { breadcrumb: "资产转移记录" },
      },
      {
        path: "/assetsRepairRecord",
        component: AssetsRepairRecord,
        meta: { breadcrumb: "资产维修记录" },
      },
      {
        path: "/assetsScrapRecord",
        component: AssetsScrapRecord,
        meta: { breadcrumb: "资产报废记录" },
      },
      {
        path: "/assetsScrapMethod",
        component: AssetsScrapMethod,
        meta: { breadcrumb: "资产报废方式" },
      },

      {
        path: "/gatewayAlerts",
        component: GatewayAlerts,
        meta: { breadcrumb: "网关日志" },
      },
      {
        path: "/nodeAlert",
        component: nodeAlert,
        meta: { breadcrumb: "节点日志" },
      },
    ],
  },

  {
    path: "/pic",
    component: pic,
    meta: { breadcrumb: "拓扑图" },
  },
  {
    path: "/pic2",
    component: pic2,
    meta: { breadcrumb: "拓扑图" },
  },

  //预警日志
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

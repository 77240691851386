import "core-js/modules/es.array.push.js";
// 清理和格式化区域树数据
const clearRegionalTree = data => {
  let response = [];
  data.forEach(item => {
    if (item.children != undefined) {
      response.push({
        label: item.name,
        value: item.id,
        children: clearRegionalTree(item.children)
      });
    } else {
      response.push({
        label: item.name,
        value: item.id
      });
    }
  });
  return response;
};

// 从服务器获取区域树数据
const regionTree = async () => {
  await serviceAxios({
    url: interfaces.reginalTree,
    method: "get"
  }).then(result => {
    let regionData = clearRegionalTree(result.data.data);
    localStorage.setItem("region", JSON.stringify(regionData));
  }).catch(err => {
    message.error("请求异常，请联系管理员");
    console.log(err);
  });
};

// 检查本地存储中是否有区域树数据
export const checkTree = async () => {
  if (localStorage.getItem("region") == null) {
    await regionTree();
  }
  return JSON.parse(localStorage.getItem("region"));
};
//租户界面特定的路由
export const specialRules = [
  {
    id: "90",
    key: "/homePageData",
    label: "首页",
    order: 0,
    title: "首页",
    is_bottom: 1,
    parent_id: "25",
  },
  {
    id: "91",
    key: "/门店列表",
    label: "门店信息",
    order: 1,
    title: "门店信息",
    is_bottom: 0,
    parent_id: "25",
    children: [
      {
        id: "92",
        key: "/customerView",
        label: "门店列表",
        order: 0,
        title: "门店列表",
        is_bottom: 1,
        parent_id: "91",
      },
      // {
      //   "id": 93,
      //   "key": "/storeMessage",
      //   "label": "运行状态",
      //   "order": 1,
      //   "title": "运行状态",
      //   "is_bottom": 1,
      //   "parent_id": 91,
      // },
      {
        id: "94",
        key: "/glopt",
        label: "拓扑数据",
        order: 2,
        title: "拓扑数据",
        is_bottom: 1,
        parent_id: "91",
      },
    ],
  },
  {
    id: "95",
    key: "/gongdandemo",
    label: "工单管理",
    order: 2,
    title: "工单管理",
    is_bottom: 0,
    parent_id: "25",
    children: [
      {
        id: "96",
        key: "/ticketView",
        label: "工单列表",
        order: 2,
        title: "工单列表",
        is_bottom: 1,
        parent_id: "95",
      },
      {
        id: "98",
        key: "/addTicket",
        label: "添加工单",
        order: 3,
        title: "添加工单",
        is_bottom: 1,
        parent_id: "95",
      },
    ],
  },
  {
    id: "101",
    key: "/IoT",
    label: "IoT",
    order: 3,
    title: "IoT",
    is_bottom: 0,
    children: [
      {
        id: "105",
        key: "/customeriotStoreConfig",
        label: "门店配置",
        order: 2,
        title: "门店配置",
        is_bottom: 1,
      },
      {
        id: "108",
        key: "/customerEnergySensorList",
        label: "电量传感器",
        order: 2,
        title: "电量传感器",
        is_bottom: 1,
      },
      {
        id: "104",
        key: "/shian",
        label: "食安管理",
        order: 2,
        title: "食安管理",
        is_bottom: 1,
        children: [
          {
            id: "102",
            key: "/customerTemperatureSensorList",
            label: "传感器列表",
            order: 2,
            title: "传感器列表",
            is_bottom: 1,
          },
          {
            id: "103",
            key: "/sensorLogList",
            label: "传感器日志列表",
            order: 2,
            title: "传感器日志列表",
            is_bottom: 1,
          },
        ],
      },

      {
        id: "1110",
        key: "/noopen105",
        label: "能耗管理",
        order: 2,
        title: "能耗管理",
        is_bottom: 1,
      },
    ],
  },

  {
    id: "110",
    key: "/noopen110",
    label: "物联网卡管理",
    order: 0,
    title: "物联网卡管理",
    is_bottom: 1,
    parent_id: "25",
    // "children": [
    //   {
    //     "id": 111,
    //     "key": "/customerView",
    //     "label": "门店列表",
    //     "order": 0,
    //     "title": "门店列表",
    //     "is_bottom": 1,
    //     "parent_id": 91,
    //   },
    // ]
  },

  {
    id: "120",
    key: "/zichanguanli",
    label: "资产管理",
    order: 0,
    title: "资产管理",
    is_bottom: 1,
    parent_id: "25",
    children: [
      {
        id: "121",
        key: "/assetsType",
        label: "资产类型",
        order: 0,
        title: "资产类型",
        is_bottom: 1,
        parent_id: "91",
      },

      {
        id: "122",
        key: "/assetsList",
        label: "资产列表",
        order: 0,
        title: "资产列表",
        is_bottom: 1,
        parent_id: "91",
      },

      {
        id: "123",
        key: "/assetsModel",
        label: "资产型号",
        order: 0,
        title: "资产型号",
        is_bottom: 1,
        parent_id: "91",
      },

      {
        id: "124",
        key: "/assetsSubSystem",
        label: "资产子系统",
        order: 0,
        title: "资产子系统",
        is_bottom: 1,
        parent_id: "91",
      },
      {
        id: "125",
        key: "/assetsUsageRecords",
        label: "资产使用记录",
        order: 0,
        title: "资产使用记录",
        is_bottom: 1,
        parent_id: "91",
      },
      {
        id: "126",
        key: "/assetsGlobalConfig",
        label: "资产全局配置",
        order: 0,
        title: "资产全局配置",
        is_bottom: 1,
        parent_id: "91",
      },
      {
        id: "127",
        key: "/assetsTransferRecord",
        label: "资产调拨记录",
        order: 0,
        title: "资产调拨记录",
        is_bottom: 1,
        parent_id: "91",
      },
      {
        id: "128",
        key: "/assetsRepairRecord",
        label: "资产维修记录",
        order: 0,
        title: "资产维修记录",
        is_bottom: 1,
        parent_id: "91",
      },
      {
        id: "129",
        key: "/assetsScrapRecord",
        label: "资产报废记录",
        order: 0,
        title: "资产报废记录",
        is_bottom: 1,
        parent_id: "91",
      },
      {
        id: "130",
        key: "/assetsScrapMethod",
        label: "资产报废方式",
        order: 0,
        title: "资产报废方式",
        is_bottom: 1,
        parent_id: "91",
      },
    ],
  },
  {
    id: "140",
    key: "/xinfa",
    label: "信发系统",
    order: 0,
    title: "信发系统",
    is_bottom: 1,
    parent_id: "25",
  },
  {
    id: "150",
    key: "/alertsLog",
    label: "告警日志",
    order: 0,
    title: "告警日志",
    is_bottom: 1,
    parent_id: "25",
    children: [
      {
        id: "151",
        key: "/gatewayAlerts",
        label: "网关告警日志",
        order: 0,
        title: "网关告警日志",
        is_bottom: 1,
        parent_id: "150",
      },
      {
        id: "152",
        key: "/sensorLogList",
        label: "传感器日志",
        order: 0,
        title: "传感器日志",
        is_bottom: 1,
        parent_id: "150",
      },
      {
        id: "153",
        key: "/nodeAlert",
        label: "节点日志",
        order: 0,
        title: "节点日志",
        is_bottom: 1,
        parent_id: "150",
      },
    ],
  },
  {
    id: "160",
    key: "/messageSendStrategy",
    label: "消息推送策略",
    order: 0,
    title: "消息推送策略",
    is_bottom: 1,
    parent_id: "25",
  },
];
